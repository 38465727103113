﻿// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=11T0u0kw0ntniuHuktcCWj5M3dJ */

$mplusp-green-base:      #BBBB00;	// Main Primary color */
$mplusp-green-light:     #F5F543;
$mplusp-green-lighter:   #E9E90C;
$mplusp-green-dark:      #989800;
$mplusp-green-darker:    #6D6D00;


$mplusp-blue-base:       #005EAB;  // MBBM blue
$mplusp-blue-light:      #0E8FFB;
$mplusp-blue-lighter:    #0083F1;
$mplusp-blue-dark:       #004A88;
$mplusp-blue-darker:     #003969;


$mplusp-red-base:        #AA2200;
$mplusp-red-light:       #E85F3A;
$mplusp-red-lighter:     #CD3810;
$mplusp-red-dark:        #871C00;
$mplusp-red-darker:      #5C1300;

$mplusp-red:        $mplusp-red-base;
$mplusp-green:      $mplusp-green-base;
$mplusp-blue:       $mplusp-blue-base;
$mplusp-orange:     #CC6611;
$mplusp-yellow:     #EECC00;

$mplusp-brown:      #886633;
$mplusp-persiangreen:#009988;

$mplusp-gray-dark:  #777777;
$mplusp-gray-light: #C4C4C4;

$mplusp-purple-dark:#331155;
$mplusp-purple-light:#8888BB;

$mplusp-green-dark: #447722;
$mplusp-green-light:#99BB66;

$mplusp-blue-dark:  #114499;
$mplusp-blue-light: #66BBEE;

$mplusp-pink-dark:  #CC2244;
$mplusp-pink-light: #DD9999;

$mplusp-link:    $mplusp-green;  
$mplusp-hover:   $mplusp-green-light;
$mplusp-active:  $mplusp-green-dark;